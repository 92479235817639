import BasePlugin from '../BasePlugin'

export default class CalculateRoutes extends BasePlugin {
  async execute () {
    const date = this.context.getModel()['planned_date']

    if (!date) {
      this.context.$message({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: 'Не выбрана дата'
      })
      return false
    }

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CalculateRoutesCommand`, {
      date: date
    })
      .then(() => {
        this.context.getDashboardComponents()['component_ab71cd4f-354e-4f7a-881a-b574bbc219ae'][0].loadData()
      })
      .catch((error) => {
        console.log(error.response.data)
      })
  }
}
